import crosssrpicon from '@/shared/icons/cross-srp-icon.svg';
import { Box, Grid, Typography } from '@mui/material';
import Image from '@/web/views/components/image/image';
import React, { useMemo } from 'react';

interface DefaultSearchItemProps {
  text: string;
  clearItem: () => void;
  removable?: boolean;
  bgColor?: string;
  color?: string;
  onClick?: () => void;
  id?: string;
}

export const DefaultSearchItem = (props: DefaultSearchItemProps) => {
  const cursorPointer = useMemo(() => ({ cursor: 'pointer' }), []);
  const removable = props.removable ?? true;

  const textToDisplay = useMemo(() => {
    if (props.text === 'Gear: Automatgear') {
      return props.text.replace('Gear: ', '');
    }
    return props.text;
  }, [props.text]);

  return (
    <Grid item>
      <Box
        id={props.id}
        onClick={props.onClick}
        style={props.onClick ? cursorPointer : {}}
        borderRadius={'100px'}
        bgcolor={props.bgColor ?? '#F2F6F8'}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        sx={{ paddingY: { xs: '8.5px', md: '12.5px' } }}
        paddingRight={1.5}
        paddingLeft={2.25}>
        <Typography
          color={props.color ?? 'initial'}
          variant="body2"
          lineHeight={'1px'}>
          {textToDisplay}
        </Typography>
        {removable ? (
          <Box
            marginLeft={1}
            display="flex"
            onClick={props.clearItem}
            style={cursorPointer}>
            <Image
              alt="Fjern søgeparameter"
              src={crosssrpicon.src}
              width={14}
              height={14}
            />
          </Box>
        ) : (
          <Box marginLeft={1} display="flex">
            <Box width={1} height={14} />
          </Box>
        )}
      </Box>
    </Grid>
  );
};
