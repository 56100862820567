import { useLoginOrCreatedEvent } from '@/shared/hooks/login-hook';
import { useSearchAgent } from '@/shared/hooks/search-agent-hook';
import {
  FormValue,
  useImmidiateSearchForm,
} from '@/shared/hooks/search-form-field-hook';
import {
  SearchPageAttribute,
  SearchPageMakeModelVariantOption,
} from '@/shared/lib-api';
import { useAppSelector } from '@/shared/store/hooks';
import {
  initialMakeModelVariantOption,
  initialSearchAttribute,
} from '@/shared/store/reducers/SRPSlice';
import { Colors } from '@/shared/util/colors';
import { capitalize } from '@/shared/util/general-helpers';
import { setHtml } from '@/shared/util/helpers/general-helper';
import { Box, Button, Grid, Typography } from '@mui/material';
import React, { FC, useCallback, useMemo } from 'react';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { DefaultSearchItem } from '../default-components/default-search-item';
import PrimaryClock from '@/shared/icons/clock-primary.svg';
import PrimaryClockYellow from '@/shared/icons/clock-yellow.svg';
import Image from '@/web/views/components/image/image';

interface SearchBarSRPProps {
  header: string;
  subHeader?: string;
  useButton?: boolean;
  theme?: 'lightBlue' | 'yellow';
  children?: React.ReactNode;
}

export const SearchBarSRP: FC<SearchBarSRPProps> = props => {
  const { add } = useSearchAgent();
  const { t } = useTranslation();
  const { field, onChange, reset } = useImmidiateSearchForm();
  const searchOptions = useAppSelector(state => state.srpReducer.searchOptions);

  const handleSaveSearchAgent = useCallback(
    () =>
      toast.success(
        <Trans
          i18nKey="SRP.SavedSearchNotificationSaved"
          components={{
            u: <u />,
            a: <a style={{ color: '#1066B6', textDecoration: 'underline' }} />,
          }}></Trans>,
        {
          autoClose: 5000,
        },
      ),
    [],
  );

  const onRemoveValue = useCallback(
    (value: FormValue, formNameOverride?: string) => {
      onChange(value, formNameOverride);
    },
    [onChange],
  );

  const saveSearchAgent = useCallback(async () => {
    add(searchOptions).then(() => {
      handleSaveSearchAgent();
    });
  }, [searchOptions, handleSaveSearchAgent, add]);

  const chips = useMemo(() => {
    return (
      field &&
      Object.entries(field).map(GenerateTagRemovable(t, false, onRemoveValue))
    );
  }, [field, onRemoveValue, t]);

  const displayClearFilter = useMemo(() => {
    return chips.some(
      p => (p !== undefined && p?.length !== 0 && p[0] !== undefined) || p?.key,
    );
  }, [chips]);

  const { loginOrCreate } = useLoginOrCreatedEvent(saveSearchAgent);

  return (
    <Box>
      <Box display={'flex'} alignItems={'center'}>
        {props.theme && (
          <Box
            width={65}
            flexShrink={0}
            sx={{ display: { xs: 'flex', md: 'none' } }}>
            <Image
              height={55}
              width={54}
              alt="Notifikation"
              src={
                props.theme === 'yellow'
                  ? PrimaryClockYellow.src
                  : PrimaryClock.src
              }
            />
          </Box>
        )}
        <Box>
          {props.children ?? (
            <Typography
              component="span"
              color={props.theme === 'yellow' ? Colors.White : undefined}
              sx={{ fontSize: { xs: 16, md: 18 } }}
              dangerouslySetInnerHTML={setHtml(props.header)}
            />
          )}
          {props.subHeader && (
            <Typography
              color={props.theme === 'yellow' ? Colors.White : undefined}
              sx={{
                fontSize: { xs: 14, md: 14 },
                lineHeight: { xs: '16px', md: '22px' },
              }}>
              {props.subHeader}
            </Typography>
          )}
        </Box>
      </Box>
      <Grid container paddingY={3} rowSpacing={1} columnSpacing={1}>
        {chips}
      </Grid>
      {!props.theme && (
        <Box alignItems="center" display={'flex'} flexDirection={'row'}>
          {displayClearFilter && (
            <>
              <Typography
                onClick={reset}
                variant="clickAble"
                id={'srp-search-bar-clearfilters'}>
                {t('SRP.ClearFilter')}
              </Typography>

              <Box
                marginX={1}
                width={'1px'}
                bgcolor={Colors.Background.Default}
              />
            </>
          )}
          <Button
            style={{
              color: '#fff',
              backgroundColor: Colors.Secondary,
              height: 30,
            }}
            onClick={loginOrCreate}>
            {t('SRP.SaveSearch')}
          </Button>
        </Box>
      )}
      {props.theme && (
        <Button
          style={{
            color: props.theme === 'yellow' ? '#277892' : '#fff',
            backgroundColor: props.theme === 'yellow' ? '#FFE08F' : '#4CA0BB',
          }}
          onClick={loginOrCreate}>
          {t('SRP.SaveSearch')}
        </Button>
      )}
    </Box>
  );
};

SearchBarSRP.displayName = 'SearchBarSRP';

const IsFieldNotToBeDisplayed = (fieldValue, fieldName) => {
  return typeof fieldValue === 'number' || fieldName === 'sort';
};

const IsFieldSearchPageAttribute = fieldValue => {
  return fieldValue.value !== undefined && fieldValue.label !== undefined;
};

const ShouldFieldSearchPageAttributeShow = fieldValue => {
  return fieldValue.value !== '' && fieldValue.label !== '';
};

const IsFieldArray = fieldValue => {
  return fieldValue.length !== undefined && fieldValue.filter !== undefined;
};

const IsString = fieldValue => {
  return typeof fieldValue === typeof '';
};

const ShouldStringShow = fieldValue => {
  return fieldValue !== '';
};

const IsMakeModelVariantField = fieldValue => {
  return fieldValue?.variant !== undefined;
};

const ShouldMakeModelVariantFieldShow = fieldValue => {
  return (
    fieldValue?.variant !== '' ||
    fieldValue?.model?.value !== '' ||
    fieldValue?.brand?.value !== ''
  );
};

export const IsValidTag = (
  t: TFunction<'translation', undefined>,
  isInnerArrayFunc: boolean,
): ((item: [string, any], index: number, array: [string, any][]) => number) => {
  return (item: [string, any], index: number, array: [string, any][]) => {
    let fieldName = item[0];
    let fieldValue = item[1];

    let formName = item[0];
    let formValue;

    if (IsFieldNotToBeDisplayed(fieldValue, fieldName)) {
      return 0;
    }
    if (IsFieldSearchPageAttribute(fieldValue)) {
      if (!ShouldFieldSearchPageAttributeShow(fieldValue)) {
        return 0;
      }
      if (isInnerArrayFunc) {
        formValue = (array as SearchPageAttribute[]).filter(
          (arrayItem, arrayIndex) => arrayIndex !== index,
        );
      } else {
        formValue = initialSearchAttribute;
      }
      fieldValue = (fieldValue as SearchPageAttribute).label;
    } else if (IsString(fieldValue)) {
      if (!ShouldStringShow(fieldValue)) {
        return 0;
      }
      formValue = '';
    } else if (IsFieldArray(fieldValue)) {
      let result = 0;
      (fieldValue as SearchPageAttribute[]).forEach((innerItem, innerIndex) => {
        result += IsValidTag(t, true)(
          [fieldName, innerItem],
          innerIndex,
          fieldValue,
        );
      });
      return result;
    } else if (IsMakeModelVariantField(fieldValue)) {
      if (!ShouldMakeModelVariantFieldShow(fieldValue)) {
        return 0;
      }
      formValue = (array as SearchPageAttribute[]).filter(
        (makeModelItem, makeModelIndex) => makeModelIndex !== index,
      );
      if (formValue.length === 0) {
        formValue = [initialMakeModelVariantOption];
      }
      fieldName = '';
      fieldValue = GetMakeModelText(
        fieldValue as SearchPageMakeModelVariantOption,
      );
    }
    return 1;
  };
};

export const GenerateTagRemovable = (
  t: TFunction<'translation', undefined>,
  isInnerArrayFunc: boolean,
  onRemoveValue?: (value: FormValue, formNameOverride?: string) => void,
  onRemoveIndex?: (index: number) => void,
) => GenerateTag(t, isInnerArrayFunc, true, onRemoveValue, onRemoveIndex);

export const GenerateTag = (
  t: TFunction<'translation', undefined>,
  isInnerArrayFunc: boolean,
  removable: boolean,
  onRemoveValue?: (value: FormValue, formNameOverride?: string) => void,
  onRemoveIndex?: (index: number) => void,
) => {
  // eslint-disable-next-line react/display-name
  return (item: [string, any], index: number, array: [string, any][]) => {
    let fieldName = item[0];
    let fieldValue = item[1];

    let formName = item[0];
    let formValue;

    if (IsFieldNotToBeDisplayed(fieldValue, fieldName)) {
      return;
    }
    if (IsFieldSearchPageAttribute(fieldValue)) {
      if (!ShouldFieldSearchPageAttributeShow(fieldValue)) {
        return;
      }
      if (isInnerArrayFunc) {
        formValue = (array as SearchPageAttribute[]).filter(
          (arrayItem, arrayIndex) => arrayIndex !== index,
        );
      } else {
        formValue = initialSearchAttribute;
      }
      fieldValue = (fieldValue as SearchPageAttribute).label;
    } else if (IsString(fieldValue)) {
      if (!ShouldStringShow(fieldValue)) {
        return;
      }
      formValue = '';
    } else if (IsFieldArray(fieldValue)) {
      return (fieldValue as SearchPageAttribute[]).map(
        (innerItem, innerIndex) =>
          GenerateTag(
            t,
            true,
            removable,
            onRemoveValue,
            onRemoveIndex,
          )([fieldName, innerItem], innerIndex, fieldValue),
      );
    } else if (IsMakeModelVariantField(fieldValue)) {
      if (!ShouldMakeModelVariantFieldShow(fieldValue)) {
        return;
      }
      if (fieldValue.temp === undefined) {
        const newArray = [
          {
            ...initialMakeModelVariantOption,
            brand: fieldValue.brand,
            temp: '',
          },
          {
            ...initialMakeModelVariantOption,
            model: fieldValue.model,
            temp: '',
          },
          {
            ...initialMakeModelVariantOption,
            variant: fieldValue.variant,
            temp: '',
          },
        ];
        return newArray.map(innerItem =>
          GenerateTag(
            t,
            true,
            removable,
            onRemoveValue,
            onRemoveIndex,
          )([fieldName, innerItem], index, array),
        );
      } else {
        let makeModelVariantItem = (
          array as SearchPageMakeModelVariantOption[]
        ).find((makeModelItem, makeModelIndex) => makeModelIndex === index);
        let copyItem = JSON.parse(JSON.stringify(makeModelVariantItem));
        if (fieldValue.variant !== '') {
          copyItem.variant = '';
        } else if (fieldValue.model.label !== '') {
          copyItem.model = initialSearchAttribute;
        } else if (fieldValue.brand.label !== '') {
          copyItem.brand = initialSearchAttribute;
          copyItem.model = initialSearchAttribute;
        }
        let copyArray = JSON.parse(
          JSON.stringify(array as SearchPageMakeModelVariantOption[]),
        );
        copyArray.splice(index, 1, copyItem);
        formValue = copyArray;
        formValue = (formValue as SearchPageMakeModelVariantOption[]).filter(
          (makeModelItem, makeModelIndex) =>
            !(
              makeModelItem.brand === initialMakeModelVariantOption.brand &&
              makeModelItem.variant === initialMakeModelVariantOption.variant &&
              makeModelItem.model === initialMakeModelVariantOption.model
            ),
        );
        if (formValue.length === 0) {
          formValue = [initialMakeModelVariantOption];
        }
        fieldName = '';
        fieldValue = GetMakeModelText(
          fieldValue as SearchPageMakeModelVariantOption,
        );
      }
    }
    return (
      <DefaultSearchItem
        key={index + fieldValue}
        text={`${
          fieldName === '' ? '' : t('SearchOptions.' + fieldName) + ':'
        } ${fieldValue == 'Træk' ? 'Anhængertræk' : fieldValue}`}
        clearItem={() => {
          if (onRemoveValue) {
            onRemoveValue(formValue, formName);
          }
          if (onRemoveIndex) {
            onRemoveIndex(index);
          }
        }}
        removable={removable}
      />
    );
  };
};

export function GetMakeModelText<SearchPageMakeModelVariantOption>(
  input: SearchPageMakeModelVariantOption,
) {
  let entries = Object.entries(input)
    .sort((a, b) => {
      return a[0].localeCompare(b[0]);
    })
    .map(entry => {
      let result = '';
      result +=
        entry[1]?.label !== undefined
          ? entry[1].label + ' '
          : typeof entry[1] !== 'string' || entry[1] === ''
          ? ''
          : capitalize(entry[0]) + ': ' + entry[1];
      return result;
    });
  return entries.join(' ');
}
