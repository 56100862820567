import { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useDebouncedValue } from 'rooks';
import {
  SearchPageAttribute,
  SearchPageMakeModelVariantOption,
  SearchPageOptions,
} from '../lib-api';
import { SearchResultExample } from '../lib-api/models/SearchResultExample';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  Reset,
  SetSrpSearchOption,
  SRPState,
  Submit,
} from '../store/reducers/SRPSlice';

type ShouldSubmitState = 'shouldSubmit' | 'shouldntSubmit';

const GetSRPSelectorValueFromFormName: any =
  (formName: string) => (state: { srpReducer: SRPState }) => {
    if (formName === undefined) {
      return state.srpReducer.searchOptions;
    }
    var formNameComponents = formName.split('.');
    var result = state.srpReducer.searchOptions;
    for (var i = 0; i < formNameComponents.length; i++) {
      result = result[formNameComponents[i]];
    }
    return result;
  };

const handleSpecialCases: (formName?: string) => undefined | string = (
  formName?: string,
) => {
  let makemodel: keyof SearchPageOptions = 'makeModelVariantOptions';
  let model: keyof SearchPageMakeModelVariantOption = 'model';
  let brand: keyof SearchPageMakeModelVariantOption = 'brand';
  if (formName === undefined) {
    return formName;
  }
  if (formName.includes(makemodel) && formName.includes(brand)) {
    return brand;
  }
  if (formName.includes(makemodel) && formName.includes(model)) {
    return model;
  }
  return formName;
};

export const GetSRPFieldExampleFromFormName =
  (formName: string) => (state: { srpReducer: SRPState }) => {
    let value = handleSpecialCases(formName);
    return state.srpReducer.searchData?.searchResultExamples.filter(
      p => p.formName === value,
    );
  };

export function useSearchForm<T>(
  formName?: string,
  submitDelay?: number,
  submitOnChange: boolean = true,
) {
  const dispatch = useAppDispatch();

  const state = useAppSelector<T>(
    GetSRPSelectorValueFromFormName(formName),
    shallowEqual,
  );

  const exampleCountState = useAppSelector<SearchResultExample[]>(
    GetSRPFieldExampleFromFormName(formName),
    shallowEqual,
  );

  const submitFunc = useCallback(() => {
    dispatch(Submit());
  }, [dispatch]);

  const reset = useCallback(() => {
    dispatch(Reset());
  }, [dispatch]);

  //forname, submitState, value
  const [value, setValue] = useState<[string, ShouldSubmitState, typeof state]>(
    [formName, 'shouldSubmit', state],
  );
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current == false) {
      isMounted.current = true;
      return;
    }
    setValue([formName, 'shouldSubmit', state]);
  }, [state, formName]);

  const [deferredValue] = useDebouncedValue(value, submitDelay);

  const onChange = useCallback(
    (value: T, formNameOverride?: string) => {
      if (formNameOverride) {
        setValue([formNameOverride, 'shouldntSubmit', value]);
      } else {
        setValue([formName, 'shouldntSubmit', value]);
      }
    },
    [formName],
  );

  const onChangeInternal = useCallback(
    (value: T, formNameOverride?: string) => {
      dispatch(
        SetSrpSearchOption({
          [formNameOverride ?? formName]: value,
        }),
      );
      if (submitOnChange) {
        submitFunc();
      }
    },
    [dispatch, formName, submitFunc, submitOnChange],
  );

  useEffect(() => {
    if (deferredValue[1] == 'shouldSubmit') {
      return;
    }
    onChangeInternal(deferredValue[2], deferredValue[0]);
  }, [deferredValue, onChangeInternal]);

  return {
    field: value[2],
    onChange: onChange,
    reset,
    fieldExamples: exampleCountState,
  };
}

export function useImmidiateSearchForm<T>(formName?: string) {
  const dispatch = useAppDispatch();

  const state = useAppSelector<T>(
    GetSRPSelectorValueFromFormName(formName),
    shallowEqual,
  );

  const submitFunc = useCallback(() => {
    dispatch(Submit());
  }, [dispatch]);

  const reset = useCallback(() => {
    dispatch(Reset());
  }, [dispatch]);

  const onChange = useCallback(
    (value: T, formNameOverride?: string, submitOnChange: boolean = true) => {
      dispatch(
        SetSrpSearchOption({
          [formNameOverride ?? formName]: value,
        }),
      );
      if (submitOnChange) {
        submitFunc();
      }
    },
    [dispatch, formName, submitFunc],
  );

  return {
    field: state,
    onChange: onChange,
    reset,
  };
}

export type FormValue =
  | string
  | string[]
  | number
  | SearchPageMakeModelVariantOption[]
  | SearchPageAttribute
  | SearchPageAttribute[];
