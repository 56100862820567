import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  marginTop?: number;
  bgColor?: string;
};

export const PadBox = ({ children, marginTop, bgColor }: Props) => {
  return (
    <Box
      sx={{
        padding: { xs: '22px 18px 22px 18px' },
        marginTop: marginTop ?? { xs: '0.6rem', md: '1rem' },
      }}
      borderRadius={2.5}
      bgcolor={bgColor ?? '#fff'}>
      {children}
    </Box>
  );
};
