import { useCallback, useMemo, useState } from 'react';
import { useApi } from '../util/api';
import { LoadingState } from '../util/general-helpers';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  addSearchAgent,
  removeSearchAgent,
  updateSearchAgent,
} from '../store/reducers/AuthSlice';
import { useRequestHelper } from './request-helper';
import { SearchAgentItem, SearchPageOptions } from '../lib-api';
import { BuildInTrackEvents, TrackEvent } from '@/shared/util/gtm';

export function useSearchAgent() {
  const dispatch = useAppDispatch();
  const { handleRequest } = useRequestHelper();
  const { searchAgentApi } = useApi();

  const add = useCallback(
    async (searchOptions: SearchPageOptions, trackingOverride?: string) => {
      let request = searchAgentApi.apiSearchagentAddsearchagentPost({
        addSearchAgentRequest: {
          options: searchOptions,
        },
      });

      let [data, errorAndState] = await handleRequest(request);
      if (errorAndState.state === LoadingState.Success) {
        dispatch(addSearchAgent(data));
        if (trackingOverride) {
          TrackEvent(trackingOverride);
        } else {
          TrackEvent(BuildInTrackEvents.OnSearchAgentCreated);
        }
      }
    },
    [searchAgentApi, dispatch, handleRequest],
  );

  const remove = useCallback(
    async (id: number) => {
      let request = searchAgentApi.apiSearchagentRemovesearchagentPost({
        removeSearchAgentRequest: {
          id,
        },
      });
      let [_, errorAndState] = await handleRequest(request);
      if (errorAndState.state === LoadingState.Success) {
        dispatch(removeSearchAgent(id));
        TrackEvent(BuildInTrackEvents.OnSearchAgentDeleted);
      }
    },
    [searchAgentApi, dispatch, handleRequest],
  );

  const toggleEmail = useCallback(
    async (id: number, recieveEmailsOnAgent: boolean) => {
      let request = searchAgentApi.apiSearchagentToggleemailsearchagentPost({
        toggleEmailSearchAgentRequest: {
          id,
          recieveEmailsOnAgent,
        },
      });
      let [item, errorAndState] = await handleRequest(request);
      if (errorAndState.state === LoadingState.Success) {
        dispatch(updateSearchAgent(item));
      }
    },
    [searchAgentApi, dispatch, handleRequest],
  );

  const edit = useCallback(
    async (id: number, name: string, searchOptions: SearchPageOptions) => {
      let request = searchAgentApi.apiSearchagentUpdatesearchagentPost({
        updateSearchAgentRequest: { id, name, options: searchOptions },
      });

      let [itemUpdated, errorAndState] = await handleRequest(request);
      if (errorAndState.state === LoadingState.Success) {
        dispatch(updateSearchAgent(itemUpdated));
        TrackEvent(BuildInTrackEvents.OnSearchAgentModified);
      }
    },
    [searchAgentApi, dispatch, handleRequest],
  );

  return {
    add,
    remove,
    edit,
    toggleEmail,
  };
}
